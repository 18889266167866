import React from "react";
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>

export const Event = ({ publication, loading }) => {
    /* let sauce; */

    if (loading) {
        return (
            <ul className="item justify-content-center">
                <h3 style={{ marginTop: '300px', height: "100%" }}>
                    Loading...
                </h3>
            </ul>
        );
    }




    return (
        <div className="publications-show">
            <div className={`col-lg-12 col-md-12`}>
                {publication.map((val) => {
                    return (

                        <div className="card mb-3" key={val.id}>

                            <div className="card-header">
                                <div className="pub-title" dangerouslySetInnerHTML={{ __html: val.titulo }} />
                            </div>
                            {publication &&
                                <div className="row card-body" style={{ padding: "0.2rem" }}>

                                    <div className="col-lg-10">
                                        <div className="card-text-event">
                                            <p style={{ fontSize: '15px', color: 'GrayText' }}>
                                                <b>Location:</b> {val.local}{/* {val.autores_array.map(({
                                                nome
                                            }) => nome).join(', ')
                                            } */}

                                            </p>
                                            <p style={{ fontSize: '15px', color: 'GrayText' }}>
                                                {val.tipo ? <><b>Type: </b>{val.tipo}</> : <></>}
                                            </p>
                                            <div className="publications-bottom">
                                                <b>Start Date: </b>{val.data_inicio}
                                                <b>End Date: </b>{ val.data_fim}
                                                <b> URL: </b>
                                                <a
                                                    href={val.url}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    {val.url}
                                                </a>
                                            </div>
                                            </div>
                                            </div>
                                            <div className="col-lg-2" style={{textAlign: 'right'}}>
                                            <Zoom>
                                                <div className="events-image">
                                                    <img
                                                        src={val.image_path
                                                            ? `https://adit.ipvc.pt/modulosipvc_producao/frontend/images/eventos/${val.image_path}`
                                                            : "https://adit.ipvc.pt/modulosipvc_producao/frontend/images/eventos/test.jpg"}
                                                        alt="Facility"
                                                        className="img-fluid"
                                                        style={{maxHeight: '82px'}}
                                                    />
                                                </div>
                                                </Zoom>
                                            </div>
                                        </div>
                            }
                        </div>


                    );

                })}
            </div>
        </div>
    );

};

export default Event;
