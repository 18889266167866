import React from 'react';


const AboutV2 = ({ pageData }) => {
    return (
        <>
            <section className="about p-120 index2">
                <div className='container-fluid'>
                    <div className="row">
                        <div className="col-lg-6" align="justify">
                            <div className='missionVision'>
                                <h2>Mission</h2>
                                <p>{pageData && pageData.MISSAO}</p>
                            </div>
                        </div>
                        <div className="col-lg-6" align="justify">
                            <div className='missionVision'>
                                <h2>Vision</h2>
                                <p>{pageData && pageData.VISAO}</p>
                            </div>
                        </div>
                    </div>  
                </div>
            </section>
            {/* <!-- end about area --> */}
        </>
    );
};

export default AboutV2;