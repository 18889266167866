import React, { useState } from 'react';
import ScrollTop from '../components/global-components/BackToTop';
import Footer from '../components/global-components/Footer';
import Indicators from '../components/section-components/Indicators';
import Service from '../components/section-components/Service';
import Layouts from '../components/global-components/Layouts';
import AboutV2 from '../components/section-components/About-v2';
import { usePageData } from '../data/usePageData';
import HeaderV2 from '../components/section-components/Header-v2';

const Home = () => {
    const { pageData } = usePageData();
    const [isLoading, setIsLoading] = useState(true);

    const handleImageLoad = () => {
        setIsLoading(false);
    };

    return (
        <>
            <Layouts>
                <HeaderV2 pageData={pageData} handleImageLoad={handleImageLoad} title={""} size='450px' />
                {isLoading ? (
                    <div className="preloader">
                    <div className="main-circle">
                        <div className="green-circle">
                            <div className="brown-circle">

                            </div>
                        </div>
                    </div>
                </div>
                ) : (
                    <>
                        <AboutV2 pageData={pageData} />
                        <Service />
                        <Indicators />
                        <Footer />
                    </>
                )}
            </Layouts>
        </>
    );
};

export default Home;
